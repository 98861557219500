* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .mobile-nav {
    position: fixed;
    bottom: 0;
    background-color: #0d354f;
    color: white;
    z-index: 1000;
    width: 100%;
    padding: 5px 0px;
  }
  .head-nav {
    color: white;
    /* text-decoration: 1px underline white !important; */
    font-size: 8px;
    font-weight: 400;
  }
  .icon {
    font-size: 20px !important;
  }
  .navbars {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .mobile-nav-combine {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #0d354f;
    padding: 13px;
    display: flex;
    /* justify-content: end; */
    color: white;
    z-index: 890;
  }
  .link.active .navbars .head-nav {
    text-decoration: 1px underline white;
  }
  .home-container {
    padding: 60px 10px 0px 10px;
    /* height: 100vh; */
  }
  .order-container {
    padding: 60px 10px 0px 10px;
    /* height: auto; */
  }
  .wallet-container {
    padding: 60px 10px 0px 10px;
    height: 100vh;
  }
  .head-txt {
    font-size: 14px;
    font-weight: 700;
  }
  .order-hr {
    border: 1px solid black !important;
  }
  .txt-1 {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
  }
  .txt-2 {
    font-size: 14px;
    font-weight: 400;
    margin: 0px !important;
  }
  .today-order {
    display: flex;
    padding: 0px 0px 20px 0px;
    flex-direction: column;
  }
  /* .img-div {
    width: 50px;
    height: 50px;
  } */
  .home-div {
    border: 1px solid #16a000;
    padding: 7px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    width: 120px;
    padding-top: 20px;
  }
  .login-container {
    padding-top: 50px;
  }
  .login {
    color: #0d354f;
    font-size: 24px;
    font-weight: 600;
  }
  .login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  label {
    font-weight: 500;
  }
  .input {
    border: 1px solid black;
  }
  .login-label-1 {
    padding-top: 20px;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-input-outlined:hover,
  :where(.css-dev-only-do-not-override-11lehqq).ant-btn-default:not(
      :disabled
    ):not(.ant-btn-disabled):hover {
    border: 1px solid #0d354f !important;
  }
  .login-btn {
    background-color: #0d354f !important;
    color: white !important;
    border: none;
    padding: 5px 40px;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-tbody
    > tr
    > th,
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-tbody
    > tr
    > td {
    border-bottom: 1px solid lightgray !important;
  }

  .txt-content {
    border-bottom: 3px solid #0d354f;
  }
  /* DivSwitcher.css */
  .div-container {
    display: flex;
    justify-content: space-around;
    border: 1px solid #0d354f;
    padding: 4px;
    border-radius: 5px;
  }
  .txt-6{
    color: #16A000;
    margin-bottom: 0px !important;
    font-size: 12px !important;
    text-decoration: 1px underline #16A000;

  }

  .div-item {
    cursor: pointer;
    border-bottom: none; /* Default no border */
  }

  .div-item.active {
    border-bottom: 4px solid #0d354f;
    border-radius: 50px 50px 0px 0px;
    transform: all 3s ease;
  }
  .txt-3 {
    font-size: 12px !important;
    margin: 0px !important;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-tbody
    .ant-table-row.ant-table-row-selected
    > .ant-table-cell {
    background-color: white !important;
  }

  /* custom-table.css */
  .ant-table .ant-checkbox-wrapper {
    color: #0d354f !important; /* Change checkbox color */
  }

  .ant-table .ant-checkbox-wrapper .ant-checkbox-inner {
    background-color: white !important; /* Change checkbox background color */
    border-color: #0d354f !important; /* Change checkbox border color */
  }

  .ant-table
    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked
    .ant-checkbox-inner {
    background-color: #0d354f !important; /* Change checked checkbox background color */
    border-color: #0d354f !important; /* Change checked checkbox border color */
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    background-color: white !important;
  }
  .select-all-container {
    display: flex;
    justify-content: space-between !important;
  }
  .order-now-button {
    background-color: #0d354f;
    border: none;
    padding: 3px 10px;
    color: white;
    border-radius: 5px;
  }
  .custom-checkbox {
    width: 16px; /* Adjust width */
    height: 16px; /* Adjust height */
    cursor: pointer;
  }
  .custom-checkbox:checked {
    background-color: #0d354f !important; /* Background color when checked */
    border-color: #0d354f !important;
  }
  .custom-checkbox:checked::after {
    content: "\2714"; /* Unicode checkmark */
    color: white; /* Color of the checkmark */
    position: absolute;
    background-color: #0d354f !important;
    top: 2px;
    left: 3px;
    font-size: 16px;
  }
  .link {
    text-decoration: none !important;
    color: white !important;
  }
  .txt-4 {
    color: #16a000;
  }
  .logo-2 {
    width: 60px !important;
  }

  .txt-5 {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .color {
    color: orangered;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    background-color: #0d354f !important;
    color: white !important;
    font-size: 14px !important;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before,
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: #0d354f !important;
  }
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child,
  :where(.css-dev-only-do-not-override-11lehqq).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-radius: 0px !important;
  }
  .item-name-column {
    width: 300px !important; /* Adjust as needed */
  }
  .css-75hvnv-MuiTableCell-root,
  .css-qgm3d7-MuiTableCell-root,
  .css-1gpapgh-MuiTableCell-root {
    color: white !important;
    border-bottom: none !important;
  }
  .css-10ukr6t-MuiTableCell-root,
  .css-174q18p-MuiTableCell-root {
    border-bottom: none !important;
  }
  .css-v73c9r-MuiPaper-root-MuiTableContainer-root {
    --Paper-shadow: none !important;
  }
  .qr-div {
    padding: 10px 0px 20px 0px;
    display: flex;
    flex-direction: column;
  }
  .btn-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .btn-2 {
    border: none;
    background-color: #0d354f;
    color: white;
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 5px;
  }
  .order-container {
    margin-top: 20px;
  }
  .complete-btn {
    background-color: #0d354f !important;
    color: #fff !important;
  }
  .logout-btn {
    background-color: #0d354f !important;
    color: #fff !important;
    font-weight: 700 !important;
  }
  .getcon-btn {
    background-color: #0d354f;
    color: #fff;
    padding: 4px 20px;
  }
  .draggable-button {
    width: 60px;
    background-color: #0d354f;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    transition: all 0.3s ease;
    z-index: 3;
  }

  .slide-container {
    position: relative;
    width: 300px;
    /* height: 40px; */
    background-color: #f0f0f0;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .order-completed {
    margin-left: 20px;
    font-weight: bold;
    font-size: 18px;
  }

  .slide {
    padding: 10px 0px 80px 0px !important;
  }
  .slide-content {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* margin-right: 20px !important; */
    color: #555; /* Ensure the content doesn't block the drag */
  }
}
